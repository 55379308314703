.carousel__item {
    @extend .carousel-item;
}

.listing-image-carousel {
    width: 100%;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-top: 76%;
    }

    .carousel-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 30px;

        @include media-breakpoint-down('sm') {
            padding: 20px;
        }
    }

    .position-relative {
        height: 100%;
    }

    .position-relative:before,
    .position-relative:after {
        content: '';
        background: $white;
        display: block;
        width: 103%;
        height: 103%;
        border: 18px solid $white;
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        left: -8px;
        top: -8px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba($black, .25);
    }

    .position-relative:before {
        transform: rotate(2deg);
    }

    .position-relative:after {
        transform: rotate(-2deg);
    }

    .carousel-item {
        display: flex;
        background: $white;
        width: 100%;
        height: 100%;
        transform: none !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }

    .carousel-item.active {
        z-index: 4;
    }

    .carousel-item-next {
        z-index: 5;
    }

    .carousel-item-right.carousel-item-prev {
        z-index: 6;
    }

    .carousel-item-image-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        border: 18px solid $white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba($black, .25);
    }

    .carousel-item-img {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
        height: 100%;
        max-width: calc(100% - 30px);
        max-height: calc(100% - 30px);
        opacity: 1;
        transition: opacity 1s easy;
    }

    .carousel-control-prev,
    .carousel-control-next {
        z-index: 9;
        width: 20%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 30px;
        height: 30px;
    }

}

#carouselFeaturedHome {
    margin: 10px 0 100px;

    @include media-breakpoint-down(sm) {
        margin-top: 50%;

        @media (min-width: 475px) and (max-width: 710px) {
            margin-top: 56%;
        }
    }

    .listing-image-carousel {
        position: absolute;
        top: -200px;
        max-height: 570px;
        max-width: 100%;
        left: 0;
        right: 0;

        @include media-breakpoint-down(lg) {
            top: -150px;
        }

        @include media-breakpoint-down(md) {
            top: -70px;
        }

        @include media-breakpoint-down(sm) {
            top: auto;
            bottom: -20px;
            max-height: 450px;
            max-width: 100%;
        }
    }

    h1 {
        @include media-breakpoint-down(lg) {
            font-size: 2.3rem;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.8rem;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .decor-brown {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .carousel-inner {
        overflow: visible;
    }

    p {
        margin: 13px 0;
    }

    p a {
        font-weight: $font-weight-bold;
        color: $white;
        font-size: 28px;
        line-height: 1.6;
        height: 180px;
        display: block;

        &:hover {
            text-decoration: none;
        }

        @include media-breakpoint-down(lg) {
            font-size: 25px;
            height: 145px;
        }

        @include media-breakpoint-down(md) {
            font-size: 20px;
            height: 130px;
        }
    }

    .carousel__item {
        opacity: 1;
    }

    .carousel-indicators {
        @include media-breakpoint-down(sm) {
            bottom: -50px;
        }

        @include media-breakpoint-up(md) {
            bottom: -15px;

            @media (max-width: 880px) {
                bottom: 0;
            }
        }

        @media (min-width: 1090px) {
            bottom: -35px;
        }

        @include media-breakpoint-up(xl) {
            bottom: -40px;
        }
    }

    .carousel-indicators li {
        background: $gray-500;
        width: 12px;
        height: 12px;
        margin: 0 8px;
        border-radius: 50%;
    }

    .carousel-indicators li.active {
        background: $primary;
    }

    .box--dashed-brown {
        padding: 20px 0;
    }
}
