.alignleft {
    float: left;
    margin: 0 20px 20px 0;
}

.alignright {
    float: right;
    margin: 0 0 20px 20px;
}

img.alignright,
img.alignleft {
    @include media-breakpoint-down(sm) {
        display: block;
        float: none;
        margin: 10px auto;
        max-width: 100%;
        height: auto;
    }
}

.rotateLeft-3 {
    transform: rotate(-3deg);
}

.rotateRight-7 {
    transform: rotate(7deg);
}
.rotateLeft-7 {
    transform: rotate(-7deg);
}

img.bordered {
    border: 18px solid $white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba($black, .25);
}

.topOffset-180 {
    margin-top: -180px;
}

img.topOffset-180 {
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
}

.topOffset40 {
    margin-top: 40px;
}
