.slider-page {
    padding: 0 $base-spacing-lg;
    position: relative;

    &-wrapper {
        height: 123px;
        overflow: hidden;
    }

    &-container {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        font-size: 0;
        padding-bottom: 20px;
        position: relative;
    }

    &-item {
        width: 144px;
        cursor: pointer;
        border: 2px solid transparent;
        position: relative;
        display: inline-block;
        margin-left: 2px;

        &:first-child {
            margin-left: 0;
        }

        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-top: 85%;
        }
    }

    &-item.active {
        border: 2px solid $primary;
    }

    &-item-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        pointer-events: none;
    }

    &-control-left,
    &-control-right {
        content: '';
        display: none;
        position: absolute;
        width: 0; 
        height: 0; 
        top: 0;
        bottom: 0;
        margin: auto;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }

    &-control-left {
        left: 0;
        border-right: 15px solid $primary;
    }

    &-control-right {
        right: 0;
        border-left: 15px solid $primary;
    }

}
