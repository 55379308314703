.box {

  display: block;
  background-color: $secondary;
  padding: 6px 0;
  box-shadow: 0px 11px 11px 0px rgba($black, .25);
  color: $black;

  &--small-shadow {
    box-shadow: 0px 11px 11px 0px rgba($black, .15);
  }

  p {
    color: $gray-800;
    font-size: 18px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $primary;
  }

  h3 {
    font-weight: $font-weight-normal;
  }

  &__content {
    padding-left: $base-spacing;
    padding-right: $base-spacing;
  }

  &--dashed .box__content {
    border-top:    2px dashed $border-color;
    border-bottom: 2px dashed $border-color;
  }

  &--dashed-brown {
    background-color: $brown;
    background-image: url(/Content/assets/img/brown_pattern.jpg);

    h3 {
      font-weight: $font-weight-bold;
    }
  }

  &--dashed-brown {
    h1, h2, h3, h4, h5, h6 {
      color: $white;
    }

    h4 {
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
    }
  }

  a {
    @include attention() {
      text-decoration: none;
    }
  }

}

article.box {
  h3 {
    font-weight: $font-weight-bold;
  }

  h4 {
    color: $black;
  }
}
