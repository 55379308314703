.logo {
  width: 433px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  img {
    width: 100%;
  }
}
