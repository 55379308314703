html {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  min-width: 320px;
}

.page {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: url(/Content/assets/img/brick_pattern.jpg);
  background-color: #efede1;
}

.page__body {
  flex: 1 0 auto;
  max-width: 100%;
  overflow-x: hidden;
}

.page__footer {
  flex-shrink: 0;
  color: $white;

  .nav-link {
    color: $white;
    font-weight: $font-weight-normal;
    font-size: $font-size-lg;
    border-right: 2px solid $white;
    line-height: 28px;
    padding: 0;
    padding-right: $base-spacing-md;
  }

  .nav-item:last-child .nav-link {
    border: none;
  }
}

.page__content {
  margin: 40px 0;
  padding-bottom: 40px;
  padding-top: $base-spacing;
}
