.btn-blue {
  background: url(/Content/assets/img/btn-blue.png) no-repeat center / 100% 100%;
  color: $white;
  border-radius: 0;

  @include attention() {
    background: $red;
    color: $white;
    outline: none;
  }
}
