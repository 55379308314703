.navbar {
  overflow-y: auto;

  @include media-breakpoint-down('sm') {
    background-color: $primary;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 9;
    width: 80%;
    box-shadow: none;
    padding: $base-spacing-lg * 2 0;
    transform: translateX(100%);
    transition: transform .3s ease;

    .navbar-nav {
      border: none;
      flex-direction: column !important;
    }

    .nav-item {
      text-transform: uppercase;
      text-align: left;
      font-weight: $font-weight-normal;
    }

    .nav-link {
      color: $white;
    }

    hr {
      border-top: 1px solid $skyDark;
    }

    @include media-breakpoint-down(sm) {
      &.menu-opened {
        display: block;
        transform: translateX(0%);
      }
    }
  }
}

.navbar__mobile-bg {
  display: none;
  opacity: 0;
  transition: background-color 1s ease;
  background-color: transparent;
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;

  @include media-breakpoint-down('sm') {
    display: block;

    &.menu-opened {
        pointer-events: auto;
        opacity: 1;
        display: block;
        background-color: rgba($black, .15);
    }
  }
}

.nav-item {
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
}

.nav-link {
  @extend .px-4;
  @extend .link;
  padding-top: $base-spacing - 5px;
  padding-bottom: $base-spacing - 5px;
}

.navbar__hamburger {
  background: $primary;
  display: none;

  &:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    background: url(/Content/assets/img/menu-button.svg) no-repeat center / contain;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @include media-breakpoint-down('sm') {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    cursor: pointer;
    left: 0;
    z-index: 99;
  }
}
