textarea.form-control.js-autosize {
    padding: $base-spacing-sm $base-spacing;
    min-height: 75px;
    padding-bottom: 10%;
}

.form-control::placeholder {
    color: $gray-600;
    font-style: italic;
}

.form-check {
    position: relative;
    padding-left: 0;
    border-radius: $border-radius;

    input[type="radio"] {
        @extend .form-check-input;
    }

    &-label {
        border: 2px solid $gray-400;
        border-radius: $border-radius;
        background-color: $white;
        cursor: pointer;
        padding: 5px $base-spacing 5px $base-spacing-lg;
        color: $dark;

        &:before {
            content: '';
            padding: 8px;
            border: 2px solid $gray-400;
            left: 6px;
        }

        &:before,
        &:after {
            position: absolute;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            background: $white;
        }
    }

    .form-check-label-borderless {
        border: none;
        background-color: transparent;
        padding: 5px 5px 5px $base-spacing-lg;
    }

    input[type="radio"] {
        position: absolute;
        left: -9999%;
    }

    input[type="radio"]:checked ~ .form-check-label:not(.form-check-label-borderless) {
        border-color: $success;
        background-color: $success;
        color: white;
    }

    input[type="checkbox"] + label {
        &:before {
            border-radius: $border-radius;
        }
    }

    input[type='radio']:checked + label:after {
        content: '';
        padding: 4px;
        border: 3px solid $success;
        left: 9px;
    }
    input[type='radio']:checked + label.form-check-label-borderless:after {
        border-color: $white;
        background: $success;
    }

    input[type='radio']:checked + label:not(.form-check-label-borderless):before {
        border-color: $white;
    }

    input[type='radio']:checked + label.form-check-label-borderless:before {
        border-color: $success;
    }

    input[type='checkbox']:checked + label:after {
        font-family: FontAwesome;
        content: '\f00c';
        color: $success;
        font-size: 1.1rem;
        padding: 4px;
        left: 0.3rem;
        background: transparent;
    }

    label {
        @extend .form-check-label;
    }
}
