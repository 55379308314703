.copy {

  &--default {
    font-size: $font-size-base !important;
  }

  &--md {
    font-size: $font-size-md;

    @include media-breakpoint-down('sm') {
      font-size: $font-size-md * .78;
    }
  }

}

h3 {
    font-size: 26px;
    margin: 30px 0 20px;
}

h4 {
  font-size: 24px;
  color: $black;
  font-weight: $font-weight-semibold;
  margin-top: 30px;
}

p {
    margin: 10px 0;
}
