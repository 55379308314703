.link {

  @include attention() {
    text-decoration: none;
    opacity: .8;
  }

  &--white {
    color: $white;

    @include attention() {
      color: $white;
    }
  }

}
