.medalion {
  margin: -40px 0 -50px;
  width: 305px;
  max-width: 100%;
  pointer-events: none;

  @include media-breakpoint-down('md') {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}
