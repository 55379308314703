.slider-page-item-img {
    pointer-events: auto !important;
}

.slider-page-item:hover .action-buttons-wrapper {
    opacity: 1;
}

.action-buttons-wrapper {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 1px;
    width: auto;
    padding: 2px 4px;
    background-color: rgba($primary, .5);
    font-size: 0;
    text-align: center;
    opacity: 0;
    transition: opacity 50ms ease-in-out;

    a {
        display: inline-block;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;

        &:first-child {
            margin-left: 0;
        }
    }

    .fas {
        color: $white;
        font-size: 14px;
        opacity: .9;
        width: 16px;
        height: 16px;
        line-height: 16px;

        &:hover {
            opacity: 1;
        }
    }
}
