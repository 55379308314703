.social-icons {
  a {
    display: block;
    width: 42px;
    height: 50px;
    background: url(/Content/assets/img/sprite_icons.svg) no-repeat 0 0;
    text-indent: -5000px;
  }

  %social-icons-item,
  li {
    transform: scale(.6);
    vertical-align: top;
  }

  &__twitter a   { background-position: -57px -240px  }
  &__facebook a  { background-position: 10px -240px   }
  &__pinterest a { background-position: -121px -240px }
  &__instagram a { background-position: -317px -240px }
  &__linkedin a  { background-position: -186px -240px }
}

.social-icons--primary {
  text-align: center;

  %social-icons-item-primary,
  li {
    transform: none;
    margin: 5px 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    height: 44px;
  }

  .social-icons__twitter a   { background-position: -56px -77px ; width: 43px; }
  .social-icons__facebook a  { background-position: 0px -77px   ; width: 19px; }
  .social-icons__pinterest a { background-position: -124px -76px; width: 38px; }
  .social-icons__instagram a { background-position: -316px -78px; width: 42px; }
  .social-icons__linkedin a  { background-position: -187px -78px; width: 40px; }
}

.propertyDetails .social-icons--primary {
  transform: scale(.75);
}

.icon {
  width: 58px;
  min-width: 58px;
  height: 58px;
  display: inline-block;
  background: url(/Content/assets/img/sprite_icons.svg) no-repeat 0 0;

  &-info-circle {
    background-position: -300px -1px;
  }

  &-at {
    background-position: -151px -1px;
  }

  &-compass {
    background-position: -1px -1px;
  }

  &-phone {
    background-position:-76px -1px;
  }

  &-address {
    background-position: -225px -1px;
  }
}

.decor {
  opacity: .5;
  display: block;
  margin: 0 auto;
  width: 260px;

  @include media-breakpoint-down(lg) {
    width: 200px;
  }

  @include media-breakpoint-down(md) {
    width: 150px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.jssocials-shares {
  @extend .social-icons;
  @extend .social-icons--primary;
}

.jssocials-share {
  @extend %social-icons-item;
  @extend %social-icons-item-primary;
  @extend .list-inline-item;
}

.jssocials-share-facebook {
  @extend .social-icons__facebook;
}

.jssocials-share-twitter {
  @extend .social-icons__twitter;
}

.jssocials-share-pinterest {
  @extend .social-icons__pinterest;
}

.jssocials-share-linkedin {
  @extend .social-icons__linkedin;
}
