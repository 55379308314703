@mixin attention($self: false) {
    transition: color $base-transition-duration ease-in-out,
    border-color $base-transition-duration ease-in-out,
    background-color $base-transition-duration ease-in-out,
    opacity $base-transition-duration ease-in-out;

    &:hover,
    &:active,
    &:focus {
        @content;
    }
}
